<template>
  <div class="page-header-title">
    <h3 class="m-b-10">{{ $t('lookupSettingsTitle') }}</h3>
  </div>
  <license-detail-api
      v-if="license"
      :license="license"
    ></license-detail-api>
  <div class="position-relative">
    <spinner-component v-if="isLoading" item-class="spinner-layout-gray"></spinner-component>
    <div class="card">
      <div class="card-header">
        <h5 class="m-b-10">{{ $t('requestsBalanceNotifications') }}</h5>
      </div>
      <div class="card-body">
        <notification-settings-form
          v-if="!isLoading"
          :has-active-subscription="hasActiveSubscription"
          :init-form="initNotificationForm"
          @form-change="changeNotificationTypeHandler"
        ></notification-settings-form>
        <div v-if="notificationFormState.action === 'send_email_notification' && notificationFormState.count" class="mt-3">
          <hr />
          <add-notification-recipient-form
            :users="contacts"
            :is-loading="isContactFormSending"
            @delete-contact="deleteContactHandler"
            @send-form="sendEmailNotificationHandler"
          ></add-notification-recipient-form>
        </div>
        <div v-if="notificationFormState.action === 'auto_charge' && notificationFormState.count" class="mt-3">
          <hr />
          <add-auto-charge-form
            :init-form="initAutoChargeForm"
            :plan-type="'lookup'"
            @send-form="sendAutoChargeHandler"
          ></add-auto-charge-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { balanceNotificationEmailCount } from '@/application/utils/limits.js'
import { computed, defineAsyncComponent, ref, onMounted } from 'vue'
import { store } from '@/application/store'
import { getNotificationSettings, saveNotificationSettings, deleteContactFromNotifications, saveNotificationParams } from '@/application/services/notification-settings.js'
import { useNotificationSettingsLogic } from '@/application/composables/notificationSettingsLogic.js'
import { useModal } from '@/application/composables/modal'
import { useI18n } from 'vue-i18n'

export default {
  name: 'ConversationApiSettingsPage',
  components: {
    'spinner-component': defineAsyncComponent(() => import('@/application/components/layout/SpinnerComponent.vue')),
    'notification-settings-form': defineAsyncComponent(() => import('@/application/components/forms/NotificationSettingsForm.vue')),
    'add-notification-recipient-form': defineAsyncComponent(() => import('@/application/components/forms/AddNotificationRecipientForm.vue')),
    'add-auto-charge-form': defineAsyncComponent(() => import('@/application/components/forms/AutoChargeForm.vue')),
    'license-detail-api': defineAsyncComponent(() => import('@/application/components/elements/LicenseDetailApi.vue')),
  },
  setup() {
    const license = computed(() => store.getters.getLicense)
    const isLoading = ref(true)
    const notificationFormState = ref({})
    const hasActiveSubscription = ref(null)
    const initNotificationForm = ref({})
    const initAutoChargeForm = ref({})
    const contacts = ref([])
    const isContactFormSending = ref(false)
    const settingsId = ref(null)
    const { openModal } = useModal()
    const i18n = useI18n()

    const { saveConversationNotificationHandler } = useNotificationSettingsLogic()

    onMounted(() => {
      getNotificationSettings().then(({ data }) => {
        hasActiveSubscription.value = data.lookup.is_active_subscriptions
        initNotificationForm.value = {
          count: data.lookup.trigger_count,
          action: data.lookup.action === 2 ? 'auto_charge' : 'send_email_notification',
        }
        notificationFormState.value = initNotificationForm.value
        initAutoChargeForm.value = {
          plan: data.lookup.plan,
          subscription: data.lookup.subscription,
        }
        contacts.value = data.lookup.emails
        settingsId.value = data.lookup.id
        isLoading.value = false
      })
    })

    return {
      isLoading,
      contacts,
      license,
      notificationFormState,
      hasActiveSubscription,
      initNotificationForm,
      initAutoChargeForm,
      isContactFormSending,
      changeNotificationTypeHandler: (data) => {
        notificationFormState.value = data
        saveNotificationParams({ ...data, id: settingsId.value, type: 'lookup' })
      },
      sendAutoChargeHandler: (data) => {
        const dataSend = {
          ...data,
          ...notificationFormState.value,
        }
        saveConversationNotificationHandler(dataSend, 'lookup')
      },
      sendEmailNotificationHandler: (data, callback) => {
        if (contacts.value.length >= balanceNotificationEmailCount) {
          openModal(
            i18n.t('error'),
            i18n.t('errorMaxNotificationEmails')
          )
          return
        }

        const dataSend = {
          ...data,
          ...notificationFormState.value,
        }
        isContactFormSending.value = true
        saveNotificationSettings({ ...dataSend, type: 'lookup' }).then(() => {
          contacts.value.push(data.email)
          callback()
          isContactFormSending.value = false
        }).catch(err => {
          console.log(err)
          isContactFormSending.value = false
        })
      },
      deleteContactHandler: (contact) => {
        const dataSend = {
          id: settingsId.value,
          contact: contact,
        }
        deleteContactFromNotifications(dataSend).then(({ data }) => {
          contacts.value = data.contacts
        }).catch(err => {
          console.log(err)
        })
      },
    }
  },
}
</script>
